
import Vue from "vue"

export default Vue.extend({
	name: "ApiError",
	props: {
        error: {
            type:  String || null || undefined,
			required: true
        }
	},
    computed: {
        getBorder(): boolean {
            if(this.error){
                const errormsg: string = this.error.toString()
                if(errormsg == "$info:NotClientOrgMember")   return true
            }
            return false
        }
    },
	methods: {
       getErrorText(): string {
           if(this.error){
                const errormsg: string = this.error.toString()
                const premsg = "Error: "
                if(errormsg.includes("403"))        return premsg + "403 (Forbidden)"
                else if(errormsg.includes("429"))   return premsg + "429 (Too many requests)"
                
                else if(errormsg == "$info:NotClientOrgMember")   return "You are not a member of any client organizations."
                return errormsg
           }

           return "Error: Unknown error occured"
       },
       getErrorType(): string {
           if(this.error){
                const errormsg: string = this.error.toString()
                if(errormsg.includes("403"))        return "error"
                else if(errormsg.includes("429"))   return "error"
                
                else if(errormsg == "$info:NotClientOrgMember")   return "warning"
                else return "error"
           }

           return "error"
       },
       getErrorColor(): string {
            if(this.error){
                const errormsg: string = this.error.toString()
                if(errormsg.includes("403"))        return "red"
                else if(errormsg.includes("429"))   return "orange"
                
                else if(errormsg == "$info:NotClientOrgMember")   return "blue"
                else return "orange"
           }

           return "red"
       },
       showRetry(): boolean {
           if(this.error){
                const errormsg: string = this.error.toString()
                if(errormsg.includes("403"))        return false
                else if(errormsg.includes("429"))   return true
                
                else if(errormsg == "$info:NotClientOrgMember")   return false
           }

           return true;
       },
       retry(): void {
           console.log("retry")
           	this.$emit('retry', null)
       }
	},
})
